import * as React from "react"
import debounce from "lodash.debounce"
import { StoreContext } from "src/context/store-context"
import { formatPrice } from "src/utils/format-price"
import { GatsbyImage } from "gatsby-plugin-image"
import { getShopifyImage } from "gatsby-source-shopify"
import Heading from 'src/components/Heading'
import { XIcon } from '@heroicons/react/outline'

export function LineItem({ item }) {
  const {
    removeLineItem,
    checkout,
    updateLineItem,
    // loading,
  } = React.useContext(StoreContext)
  const [quantity, setQuantity] = React.useState(item.quantity)

  const variantImage = {
    ...item.variant.image,
    originalSrc: item.variant.image.src,
  }
  const price = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount)
  )

  const subtotal = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount) * quantity
  )

  const handleRemove = () => {
    removeLineItem(checkout.id, item.id)
  }

  const uli = debounce(
    (value) => updateLineItem(checkout.id, item.id, value),
    300
  )
  // eslint-disable-next-line
  const debouncedUli = React.useCallback((value) => uli(value), [])

  // eslint-disable-next-line
  const handleQuantityChange = (value) => {
    if (value !== "" && Number(value) < 1) {
      return
    }
    setQuantity(value)
    if (Number(value) >= 1) {
      debouncedUli(value)
    }
  }

  // eslint-disable-next-line
  function doIncrement() {
    handleQuantityChange(Number(quantity || 0) + 1)
  }

  // eslint-disable-next-line
  function doDecrement() {
    handleQuantityChange(Number(quantity || 0) - 1)
  }

  const image = React.useMemo(
    () =>
      getShopifyImage({
        image: variantImage,
        layout: "constrained",
        crop: "contain",
        width: 160,
        height: 160,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variantImage.src]
  )

  return (
    <tr className="border-b border-[#cfcfcf] lg:table-row flex flex-col">
      <td className="py-6">
        <div className="flex flex-row items-start justify-start gap-6">
          {image && (
            <GatsbyImage
              key={variantImage.src}
              image={image}
              alt={variantImage.altText ?? item.variant.title}
              className="md:max-w-[82px] max-w-[100px]"
            />
          )}
          <div>
            <Heading size="h5" className="leading-5 lg:text-2xl mb-0">{item.title}{item.customAttributes.length > 0 && ` - ${item.variant.title}`}</Heading>
            {
              item.customAttributes.length > 0 &&
                <div className="mb-4">
                  {item.customAttributes.map((attribute, index) => {
                    if( attribute.value !== "" ) {
                      return(
                        <div className="flex gap-1 flex-col lg:flex-row" key={`${attribute.key}-${index}`}>          
                          <span className="text-sm font-display">{attribute.key}:</span>            
                          <span className="text-sm font-display">{attribute.value}</span>
                        </div>
                      )
                    }
                    return null
                  })}
                </div>
            }
            {
              item.discountAllocations.length > 0 &&
              <div className="text-red font-display mb-4">{item.discountAllocations[0].discountApplication.title} ({formatPrice(item.discountAllocations[0].allocatedAmount.currencyCode, item.discountAllocations[0].allocatedAmount.amount)})</div>
            }
            {/* Mobile view start */}
            <div className="flex flex-col gap-2">
              <td className="mt-4 mb-2 text-darkgrey font-display text-xl lg:hidden">{quantity}</td>
              <td className="text-darkgrey font-display text-xl lg:hidden">{subtotal}</td>
              <button
                onClick={handleRemove}
                className="flex text-red hover:text-darkred duration-300 flex-row items-center text-xs font-body gap-1"  
              >
                <XIcon className="h-3 w-3" aria-hidden="true" />
                Remove
              </button>
            </div>
            {/* Mobile view end */}
          </div>
        </div>
      </td>
      {/* Desktop view start */}
      <td className="py-6 text-darkgrey font-display text-2xl lg:table-cell hidden">{price}</td>
      <td className="py-6 text-darkgrey font-display text-2xl lg:table-cell hidden">{quantity}</td>
      <td className="py-6 text-darkgrey font-display text-2xl lg:table-cell hidden">{subtotal}</td>
      {/* Desktop view end */}
    </tr>
  )
}

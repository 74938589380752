import * as React from "react";
import { Link } from "gatsby";
import resolveLink from "../utils/resolveLink";
import Layout from "src/components/Layout";
import { StoreContext } from "src/context/store-context";
import { LineItem } from "src/components/Shopify/LineItem";
import { formatPrice } from "src/utils/format-price";
import Heading from "src/components/Heading";
import DiscountInput from "src/components/Shopify/DiscountInput";

export default function CartPage() {
  const { checkout, loading } = React.useContext(StoreContext);
  const emptyCart = checkout.lineItems.length === 0;

  const handleCheckout = () => {
    window.location.assign(checkout.webUrl);
  };

  return (
    <Layout>
      <div className="max-w-[1440px] mx-auto py-16 lg:px-16 px-6">
        {emptyCart ? (
          <div className="text-center mx-auto xl:py-60 lg:py-32 py-24">
            <Heading size="h1" className="text-4xl lg:text-5xl text-darkgrey">
              Your basket is empty
            </Heading>
            <p className="font-body text-sm text-darkgrey mb-8">
              You currently have no products in your basket.
              <br />
              To continue shopping, click the button to view our products.
            </p>
            <Link
              to={resolveLink("/cx-tools-shop/")}
              className="!leading-[0] cursor-pointer inline-flex justify-center items-center px-[30px] py-[15px] line-crop font-display tracking-normal text-white uppercase transition duration-300 text-lg bg-red hover:bg-darkred"
            >
              View all products
              <i className="fas fa-chevron-right cursor-pointer ml-3 text-xs inline"></i>
            </Link>
          </div>
        ) : (
          <>
            <Heading size="h2" className="text-darkgrey lg:mb-6">
              Basket
            </Heading>
            <table className="w-full table-auto">
              <thead className="lg:border-b border-[#cfcfcf]">
                <tr className="font-display uppercase text-darkgrey tracking-normal text-left">
                  <th className="xl:w-[75%] w-[70%] pb-2 lg:table-cell hidden">
                    Product
                  </th>
                  <th className="xl:w-[7%] w-[10%] pb-2 lg:table-cell hidden">
                    Price
                  </th>
                  <th className="xl:w-[13%] w-[15%] pb-2 lg:table-cell hidden">
                    Quantity
                  </th>
                  <th className="w-[5%] pb-2 lg:table-cell hidden">Total</th>
                </tr>
              </thead>
              <tbody>
                {checkout.lineItems.map((item) => (
                  <LineItem item={item} key={item.id} />
                ))}
              </tbody>
            </table>
            <div className="lg:ml-auto xl:max-w-[25%] lg:max-w-[30%] flex flex-col gap-4 mt-12">
              <div className="flex flex-row justify-between items-center font-body text-sm text-darkgrey">
                <span>Subtotal</span>
                <span>
                  {formatPrice(
                    checkout.subtotalPriceV2.currencyCode,
                    checkout.subtotalPriceV2.amount
                  )}
                </span>
              </div>
              <div className="flex flex-row justify-between items-center font-body text-sm text-darkgrey">
                <span>VAT calculated at checkout</span>
              </div>
              <div className="flex flex-row justify-between items-center font-display text-2xl uppercase text-darkgrey border-t border-[#cfcfcf] pt-4">
                <span>Total Price</span>
                <span>
                  {formatPrice(
                    checkout.totalPriceV2.currencyCode,
                    checkout.totalPriceV2.amount
                  )}
                </span>
              </div>
              <DiscountInput />
              <button
                onClick={handleCheckout}
                disabled={loading}
                className="w-full !leading-[0] cursor-pointer inline-flex justify-center items-center px-[30px] py-[15px] line-crop font-display tracking-normal text-white uppercase transition duration-300 text-lg bg-red hover:bg-darkred"
              >
                Checkout
                <i className="fas fa-chevron-right cursor-pointer ml-3 text-xs inline"></i>
              </button>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
}

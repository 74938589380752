import React, { useContext, useState } from "react"
import { StoreContext } from "src/context/store-context"

const DiscountInput = () => {

    const { setDiscount, loading } = useContext(StoreContext)
    const [discountCode, setDiscountCode] = useState("")

    return(
        <div className="my-5 flex flex-row">
            <input className="block w-full shadow-sm py-3 px-4 placeholder-placeholdergrey focus:ring-darkred focus:border-darkred border-[#e0e0e0] font-display text-lg mr-3" type="text" name="discount" id="discount" placeholder="Discount" onChange={e => { setDiscountCode(e.target.value); }} disabled={loading ? "disabled" : ""}/>
            <button className="!leading-[0] cursor-pointer inline-flex justify-center items-center px-[30px] py-[15px] line-crop font-display tracking-normal text-white uppercase transition duration-300 text-lg bg-red hover:bg-darkred" onClick={(e) => setDiscount(discountCode)} disabled={loading ? "disabled" : ""}>Apply</button>
        </div>
    )

}

export default DiscountInput